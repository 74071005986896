import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputNumber from "../common/form/labelAndInputNumber";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";
import FormatUtils from "../common/formatUtils/FormatUtils";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import { filtrarUcPorDataDesligamento } from "../utils/filtrarUcPorDataDesligamento";

import { setModoTela, initForm, initFormAmpliacao, getListaAmpliacao } from "./geracaoActions";

class GeracaoForm extends Component {
	state = {};

	componentWillMount() {
		this.props.getListaAmpliacao(this.props.formularioValues.id || null);
	}

	render() {
		let readOnly = this.props.excluir || this.props.visualizacao ? "readOnly" : "";

		let unidadeConsumidora = this.props.listaUnidadeConsumidora.filter((item) => item.id == this.props.formularioValues.id_unidade_consumidora)[0];
		const nomesMeses = ["janeiro", "fevereiro", "marco", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name="id_cliente"
								component={Select}
								//se usuario implantacao estiver logado
								//filtra para poder incluir apenas itens na UC
								//que ele é o implantador
								options={
									this.props.usuarioLogado.usuario_implantacao
										? this.props.listaCliente.filter((cliente) =>
												cliente.itens.find((uc) => uc.id_usuario_implantacao == this.props.usuarioLogado.id)
										  )
										: this.props.listaCliente
								}
								label="Cliente"
								cols="12 12 12 12"
								placeholder="Selecione o cliente"
								readOnly={readOnly}
							/>
						</Row>
						<Row>
							<Field
								name="id_unidade_consumidora"
								component={Select}
								//se usuario implantacao estiver logado
								//filtra para poder incluir apenas itens na UC
								//que ele é o implantador
								options={this.props.listaUnidadeConsumidora.filter((item) => {
									const implantacaoLogado = this.props.usuarioLogado.usuario_implantacao;
									const ucClienteSelecionado = item.id_cliente == this.props.formularioValues.id_cliente;
									if (implantacaoLogado) {
										const usuarioImplantacaoVinculadoUc = item.id_usuario_implantacao == this.props.usuarioLogado.id;
										return usuarioImplantacaoVinculadoUc && ucClienteSelecionado;
									}
									return ucClienteSelecionado;
								})}
								label="Unidade Consumidora"
								cols="12 12 12 12"
								placeholder="Selecione a unidade consumidora"
								readOnly={readOnly}
							/>
						</Row>

						<Row>
							<Field
								name="competencia_inicio"
								component={LabelAndInputMask}
								label="Competência Inicial"
								placeholder="Informe a competência"
								cols="12 6 4"
								mask="99/9999"
								readOnly={!!this.props.formularioValues.id}
							/>
						</Row>

						{unidadeConsumidora && !unidadeConsumidora.remota ? (
							<Row>
								<LabelAndInputNumber
									name="valor_investido"
									label="Valor Investido"
									placeholder="Informe o valor"
									cols="6 4 3 2"
									casas={2}
									readOnly={readOnly}
									value={this.props.formularioValues.valor_investido}
									onChange={(data) => {
										this.props.initForm({
											...this.props.formularioValues,
											valor_investido: data.target.value,
										});
									}}
								/>
							</Row>
						) : null}

						<ContentCard>
							<ContentCardHeader>
								<label>Consumo Anterior</label>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									{nomesMeses.map((nomeMes, i) => (
										<LabelAndInputNumber
											name={`anterior_${nomeMes}`}
											label={i === 2 ? "Março" : `${nomeMes[0].toUpperCase()}${nomeMes.substring(1)}`}
											placeholder="Informe a quantidade"
											cols="6 4 3 2"
											casas={2}
											readOnly={readOnly}
											value={this.props.formularioValues[`anterior_${nomeMes}`]}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													[`anterior_${nomeMes}`]: data.target.value,
												});
											}}
										/>
									))}
								</Row>
							</ContentCardBody>
						</ContentCard>

						{unidadeConsumidora && !unidadeConsumidora.remota ? (
							<ContentCard>
								<ContentCardHeader>
									<label>Produção Esperada</label>
								</ContentCardHeader>
								<ContentCardBody>
									<Row>
										{nomesMeses.map((nomeMes, i) => (
											<LabelAndInputNumber
												name={`${nomeMes}`}
												label={i === 2 ? "Março" : `${nomeMes[0].toUpperCase()}${nomeMes.substring(1)}`}
												placeholder="Informe a quantidade"
												cols="6 4 3 2"
												casas={2}
												readOnly={readOnly}
												value={this.props.formularioValues[nomeMes]}
												onChange={(data) => {
													this.props.initForm({
														...this.props.formularioValues,
														[nomeMes]: data.target.value,
													});
												}}
											/>
										))}
									</Row>
								</ContentCardBody>
							</ContentCard>
						) : null}

						{unidadeConsumidora && !unidadeConsumidora.remota ? (
							<ContentCard>
								<ContentCardHeader>
									<label>Informações do Inversor</label>
								</ContentCardHeader>
								<ContentCardBody>
									<Row>
										<LabelAndInputNumber
											name="inversor_quantidade"
											label="Quantidade"
											placeholder="Informe a quantidade"
											cols="6 4 3 2"
											casas={0}
											readOnly={readOnly}
											value={this.props.formularioValues.inversor_quantidade}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													inversor_quantidade: data.target.value,
												});
											}}
										/>

										<Field
											name="inversor_marca_modelo"
											component={LabelAndInput}
											label="Marca e Modelo"
											placeholder="Informe a marca e o modelo"
											cols="6 4 3 6"
											readOnly={readOnly}
										/>

										<LabelAndInputNumber
											name="inversor_potencia_individual"
											label="Potência Individual (Kw)"
											placeholder="Informe a potência"
											cols="6 4 3 2"
											casas={2}
											readOnly={readOnly}
											value={this.props.formularioValues.inversor_potencia_individual}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													inversor_potencia_individual: data.target.value,
												});
											}}
										/>

										<LabelAndInputNumber
											name="inversor_potencia_total"
											label="Potência Total (KW)"
											placeholder="Informe a potência"
											cols="6 4 3 2"
											casas={2}
											readOnly={readOnly}
											value={this.props.formularioValues.inversor_potencia_total}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													inversor_potencia_total: data.target.value,
												});
											}}
										/>
									</Row>
								</ContentCardBody>
							</ContentCard>
						) : null}

						{unidadeConsumidora && !unidadeConsumidora.remota ? (
							<ContentCard>
								<ContentCardHeader>
									<label>Informações do Módulo</label>
								</ContentCardHeader>
								<ContentCardBody>
									<Row>
										<LabelAndInputNumber
											name="modulos_quantidade"
											label="Quantidade"
											placeholder="Informe a quantidade"
											cols="6 4 3 2"
											casas={0}
											readOnly={readOnly}
											value={this.props.formularioValues.modulos_quantidade}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													modulos_quantidade: data.target.value,
												});
											}}
										/>

										<Field
											name="modulos_marca_modelo"
											component={LabelAndInput}
											label="Marca e Modelo"
											placeholder="Informe a marca e o modelo"
											cols="12 6 3 6"
											readOnly={readOnly}
										/>

										<LabelAndInputNumber
											name="modulos_potencia_individual"
											label="Potência Individual (Wtts)"
											placeholder="Informe a potência"
											cols="6 4 3 2"
											casas={2}
											readOnly={readOnly}
											value={this.props.formularioValues.modulos_potencia_individual}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													modulos_potencia_individual: data.target.value,
												});
											}}
										/>

										<LabelAndInputNumber
											name="modulos_potencia_total"
											label="Potência Total (Kw)"
											placeholder="Informe a potência"
											cols="6 4 3 2"
											casas={2}
											readOnly={readOnly}
											value={this.props.formularioValues.modulos_potencia_total}
											onChange={(data) => {
												this.props.initForm({
													...this.props.formularioValues,
													modulos_potencia_total: data.target.value,
												});
											}}
										/>
									</Row>
								</ContentCardBody>
							</ContentCard>
						) : null}

						{unidadeConsumidora && !unidadeConsumidora.remota && this.props.formularioValues.id ? (
							<ContentCard>
								<ContentCardHeader>
									<label>Ampliações</label>
								</ContentCardHeader>
								<ContentCardBody>
									<Row>
										<Grid cols="6 6 4 2">
											<Button
												text="Adicionar"
												type={"success"}
												icon={"fa fa-plus"}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTela("cadastroAmpliacao", this.props.formularioValues);
													this.props.initFormAmpliacao({
														id_geracao: this.props.formularioValues.id,
													});
												}}
											/>
										</Grid>
									</Row>
									<Table responsive>
										<THead>
											<Tr>
												<Th alignLeft width={200}>
													Competência
												</Th>
												<Th alignCenter>Janeiro</Th>
												<Th alignCenter>Fevereiro</Th>
												<Th alignCenter>Março</Th>
												<Th alignCenter>Abril</Th>
												<Th alignCenter>Maio</Th>
												<Th alignCenter>Junho</Th>
												<Th alignCenter>Julho</Th>
												<Th alignCenter>Agosto</Th>
												<Th alignCenter>Setembro</Th>
												<Th alignCenter>Outubro</Th>
												<Th alignCenter>Novembro</Th>
												<Th alignCenter>Dezembro</Th>
												<Th alignCenter width={100}></Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaAmpliacao.map((item) => {
												const mesesAmpliacoes = {};
												nomesMeses.forEach((nomeMes) => (mesesAmpliacoes[nomeMes] = FormatUtils.formatarValorTela(item[nomeMes], 2)));
												const registro = {
													...item,
													competencia: `${String(item.competencia).substring(4, 6)}/${String(item.competencia).substring(0, 4)}`,
													...mesesAmpliacoes,
													inversor_quantidade: FormatUtils.formatarValorTela(item.inversor_quantidade, 0),
													inversor_potencia_individual: FormatUtils.formatarValorTela(item.inversor_potencia_individual, 2),
													inversor_potencia_total: FormatUtils.formatarValorTela(item.inversor_potencia_total, 2),
													modulos_quantidade: FormatUtils.formatarValorTela(item.modulos_quantidade, 0),
													modulos_potencia_individual: FormatUtils.formatarValorTela(item.modulos_potencia_individual, 2),
													modulos_potencia_total: FormatUtils.formatarValorTela(item.modulos_potencia_total, 2),
													valor_investido: FormatUtils.formatarValorTela(item.valor_investido, 2),
												};
												return (
													<Tr
														key={item.id}
														className={`${this.state.linhaSelecionada == item.ano ? "hover" : ""}`}
														onClick={() =>
															this.setState({
																...this.state,
																linhaSelecionada: item.id,
															})
														}
														onMouseEnter={() =>
															this.setState({
																...this.state,
																linhaSelecionada: item.id,
															})
														}
													>
														<Td>
															{String(item.competencia).substring(4, 6)}/{String(item.competencia).substring(0, 4)}
														</Td>
														{nomesMeses.map((nomeMes) => (
															<Td alignCenter>{FormatUtils.formatarValorTela(item[nomeMes], 2)}</Td>
														))}

														<Td alignRight minWidth={100}>
															<ButtonTable
																type={"warning"}
																icon={"fas fa-pencil-alt"}
																visible={this.state.linhaSelecionada == item.id}
																disabled={readOnly ? true : false}
																event={() => {
																	this.props.setModoTela("cadastroAmpliacao", registro);

																	this.props.initFormAmpliacao(registro);
																}}
															/>
															<ButtonTable
																type={"danger"}
																icon={"fas fa-trash-alt"}
																visible={this.state.linhaSelecionada == item.id}
																disabled={readOnly ? true : false}
																event={() => {
																	this.props.setModoTela("exclusaoAmpliacao", registro);
																	this.props.initFormAmpliacao(registro);
																}}
															/>
														</Td>
													</Tr>
												);
											})}
										</TBody>
									</Table>
								</ContentCardBody>
							</ContentCard>
						) : null}
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols="6 6 4 3">
								{this.props.excluir ? (
									<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} disabled={this.props.visualizacao} />
								) : (
									<Button text="Salvar" submit type={"success"} icon={"fa fa-check"} disabled={this.props.visualizacao} />
								)}
							</Grid>
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

GeracaoForm = reduxForm({ form: "geracaoForm", destroyOnUnmount: false })(GeracaoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	usuarioLogado: state.auth.usuarioLogado,
	formularioValues: getFormValues("geracaoForm")(state),
	registro: state.geracao.registro,
	listaCliente: state.geracao.listaCliente,
	listaUnidadeConsumidora: filtrarUcPorDataDesligamento(state.geracao.listaUnidadeConsumidora).filter((uc) => uc.geracao || uc.remota),
	listaAmpliacao: state.geracao.listaAmpliacao,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			initForm,
			initFormAmpliacao,
			getListaAmpliacao,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(GeracaoForm);
