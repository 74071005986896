import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";

export function setModoTela(modo, registro = {}) {
    return {
        type: "MONITORAMENTO_CONSUMO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}

export function setAguardando(aguardando) {
    return {
        type: "MONITORAMENTO_CONSUMO_SET_AGUARDANDO",
        payload: aguardando,
    };
}

export function setFiltro(filtro) {
    return {
        type: "MONITORAMENTO_CONSUMO_FILTRO",
        payload: filtro,
    };
}
export function setDataInicialGrafico(data) {
    return {
        type: "MONITORAMENTO_CONSUMO_SET_DATA_INICIAL_GRAFICO",
        payload: data,
    };
}

export function setDataFinalGrafico(data) {
    return {
        type: "MONITORAMENTO_CONSUMO_SET_DATA_FINAL_GRAFICO",
        payload: data,
    };
}

export async function getDemandaContagem() {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            const aguardando = getState().monitoramentoConsumo.aguardando;
            if (aguardando || !usuarioLogado) return {};
            dispatch(setAguardando(true));
            dispatch({
                type: "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO",
                payload: { data: [] },
            });
            const filtro = getState().monitoramentoConsumo.filtro;
            const idCliente = usuarioLogado.acesso_cliente ? usuarioLogado.id_cliente : filtro.id_cliente;
            const tipoContratoDemanda = filtro.id_tipo_contrato_demanda || 0;
            const resp = await axios.get(`${consts.API_URL}/demandaContrato/listarContagemComAgendamentoDemanda?
			id_cliente=${idCliente}&id_tipo_contrato_demanda=${tipoContratoDemanda}`);

            const listaConsumoLeitura = await axios.get(`${consts.API_URL}/consumoLeitura?id_cliente=${idCliente}`);
            // console.log();

            //garante que "agendamentos" vai ser um array e filtra as UCs conforme as selecionadas no filtro de UC
            const listaDemandaComAgendamento = (resp.data || [])
                .filter((item) => !filtro.unidades_consumidoras.length || filtro.unidades_consumidoras.find((uc) => uc.value == item.id_unidade_consumidora))
                .map((demanda) => {
                    const consumoLeitura = (listaConsumoLeitura.data || []).find((consumo) => consumo.id_unidade_consumidora == demanda.id_unidade_consumidora) || {};
                    return {
                        ...demanda,
                        agendamentos: demanda.agendamentos || [],
                        consumoLeitura,
                    };
                });

            // ORDENA PELOS ALERTAS DE P(kW) e FP -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            const calcularFatorPotencia = (item) => {
                const kw = parseFloat(item.consumoLeitura.kw);
                const kvarInd = parseFloat(item.consumoLeitura.kvar_ind) || 0;
                return kw / Math.sqrt(Math.pow(kw, 2) + Math.pow(kvarInd, 2));
            };
            const validarPkwMaiorDemandaContratada = (item) => {
                return (
                    (filtro.id_tipo_contrato_demanda == 1 && parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_fora_ponta_contratada_mes_atual) * 1.05) ||
                    (filtro.id_tipo_contrato_demanda == 2 && parseFloat(item.consumoLeitura.kw) > parseFloat(item.demanda_ponta_contratada_mes_atual) * 1.05)
                );
            };

            const listaAlertasPendentes = listaDemandaComAgendamento
                .filter((item) => (!item.consumoLeitura.status_visualizado_kw && validarPkwMaiorDemandaContratada(item)) || (!item.consumoLeitura.status_visualizado_fp && calcularFatorPotencia(item) < 0.92))
                .sort((a, b) => calcularFatorPotencia(a) - calcularFatorPotencia(b));

            const listaAlertasVisualizados = listaDemandaComAgendamento
                .filter((item) => (item.consumoLeitura.status_visualizado_kw && validarPkwMaiorDemandaContratada(item)) || (item.consumoLeitura.status_visualizado_fp && calcularFatorPotencia(item) < 0.92))
                .sort((a, b) => calcularFatorPotencia(a) - calcularFatorPotencia(b));

            const listaSemAlertas = listaDemandaComAgendamento
                .filter((item) => !item.consumoLeitura.status_visualizado_kw && !validarPkwMaiorDemandaContratada(item) && !item.consumoLeitura.status_visualizado_fp && (calcularFatorPotencia(item) || 1) >= 0.92)
                .sort((a, b) => a.nome_cliente.localeCompare(b.nome_cliente));

            dispatch({
                type: "MONITORAMENTO_CONSUMO_DEMANDA_CONTAGEM_LISTADO",
                payload: {
                    data: [...listaAlertasPendentes, ...listaAlertasVisualizados, ...listaSemAlertas],
                },
            });

            //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

//listas usadas nos filtros
export function carregarListas() {
    return (dispatch, getState) => {
        dispatch([setAguardando(true), getListaCliente(), getListaUnidadeConsumidora(), getListaDemandaContratoTipo(), setAguardando(false)]);
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: "MONITORAMENTO_CONSUMO_CLIENTE_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaUnidadeConsumidora() {
    const request = axios.get(`${consts.API_URL}/unidadeConsumidora/listarSelect`);
    return {
        type: "MONITORAMENTO_CONSUMO_UNIDADE_CONSUMIDORA_SELECT_LISTADO",
        payload: request,
    };
}

export function getListaDemandaContratoTipo() {
    const request = axios.get(`${consts.API_URL}/demandaContratoTipo/listarselect`);
    return {
        type: "MONITORAMENTO_CONSUMO_CONTRATO_DEMANDA_TIPO_SELECT_LISTADO",
        payload: request,
    };
}

// GRÁFICOS
export function getListaGraficoTodasIntegracoesAgrupadasPorHora() {
    return (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            const aguardando = getState().monitoramentoConsumo.aguardando;
            if (aguardando || !usuarioLogado) return {};
            const data = getState().consumoLeitura.dataGrafico;
            const { id_cliente, id_unidade_consumidora, intervalo_tempo } = getState().consumoLeitura.filtro;
            const resposta = axios.get(
                `${consts.API_URL}/consumoLeitura/listaGraficoTodasIntegracoesAgrupadasPorIntervaloTempo?data=${data}&id_cliente=${id_cliente}&id_unidade_consumidora=${id_unidade_consumidora}&intervalo_tempo=${intervalo_tempo}`
            );
            dispatch({
                type: "MONITORAMENTO_CONSUMO_LISTA_GRAFICO_TODAS_INTEGRACOES_AGRUPADAS_POR_HORA",
                payload: resposta,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function getListaGraficoDia({ id_consumo_integracao, dataInicial, dataFinal }) {
    return async (dispatch, getState) => {
        try {
            const usuarioLogado = getState().auth.usuarioLogado;
            const aguardando = getState().monitoramentoConsumo.aguardando;
            if (aguardando || !usuarioLogado) return {};

            dispatch(setAguardando(true));

            const resposta = axios.get(`${consts.API_URL}/consumoLeitura/listaGraficoDia?id_consumo_integracao=${id_consumo_integracao || null}&data_inicial=${dataInicial}&data_final=${dataFinal}`);
            dispatch({
                type: "MONITORAMENTO_CONSUMO_LISTA_GRAFICO_DIA",
                payload: resposta,
            });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}

export async function setStatusVisualizado({ id, coluna }) {
    return async (dispatch, getState) => {
        try {
            dispatch(setAguardando(true));
            await axios.patch(`${consts.API_URL}/consumoLeitura/setStatusVisualizado`, { id, coluna });
        } catch (erro) {
            setErroAPI(erro);
        } finally {
            dispatch(setAguardando(false));
        }
    };
}
